import React, { useEffect, SetStateAction, useState, useRef } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../functions/RunFetch";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

type PopupProps = {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
};

interface BuyerSummaryObj {
  Buyer: string;
  Markups: string;
  Markdowns: string;
}

function BuyerSummaryPopup({ show, setShow }: PopupProps) {
  const [buyerSummaryList, setBuyerSummaryList] = useState<BuyerSummaryObj[]>([]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    getBuyerSummary();
  };

  const getBuyerSummary = async () => {
    const res = await runFetch(
      spURL_POST + "Portal_PriceChange_BuyerSummary",
      getReqOpt_POST("[{}]")
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      console.log(resObj.exception);
    } else if (resObj.status === "OK!") {
      if (resObj.response.length > 0) {
        const spResponse: any = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setBuyerSummaryList(
            spResponse.Table.map((item: BuyerSummaryObj) => ({
              Buyer: item.Buyer,
              Markups: item.Markups,
              Markdowns: item.Markdowns,
            }))
          );
        }
      }
    }
  };

  const renderBuyerSummary = () => {
    let PopupBody;
    if (buyerSummaryList.length === 0) {
      PopupBody = (
        <strong className="default-alignment">
          There are no items with price changes for this week.
        </strong>
      );
    } else {
      PopupBody = (
        <div className="default-alignment">
          <table className="itemlist-table">
            <thead className="bold">
              <tr>
                <td>Buyer</td>
                <td>Markups</td>
                <td>Markdowns</td>
              </tr>
            </thead>
            <tbody>
              {buyerSummaryList.map((item, index) => (
                <tr>
                  <td key={"buyer" + index} className="cell-pading-5">
                    {item.Buyer}
                  </td>
                  <td key={"markup" + index} className="cell-pading-5">
                    {item.Markups}
                  </td>
                  <td key={"markdown" + index} className="cell-pading-5">
                    {item.Markdowns}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return PopupBody;
  };

  return (
    <div>
      <Modal
        size="sm"
        show={show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="grid-container">{renderBuyerSummary()}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="popup-button-container">
            <div>
              <Button onClick={handleClose}>Close</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BuyerSummaryPopup;
