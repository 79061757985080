import { Key, MouseEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../../functions/RunFetch";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";
import Header from "../../components/Header";
// import FilterAccordion from "../../components/FilterAccordion";
import ItemCardPriceChange from "../../components/ItemCardPriceChange";
import { IsArrayEmpty } from "../../functions/IsArrayEmpty";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { GetValueOfKey } from "../../functions/GetValueOfKey";
import { StringWithNoSpace } from "../../functions/StringWithNoSpace";
import MyOffcanvas from "../../components/MyOffcanvas";
import PopupSummary from "../../components/PopupSummary";
import PopupCustomFilter from "../../components/PopupCustomFilter";
import PopupUserVerify from "../../components/PopupUserVerify";
import ProcessPopup from "../../components/PopupProcess";
import PopupProcess from "../../components/PopupProcess";
import PopupBuyerSummary from "../../components/PopupBuyerSummary";
import SmartFilterAccordion from "../../components/SmartFilterAccordion";

export interface PriceChangeItemObj {
  ItemNo: string;
  ImgURL: string;
  Description: string;
  SupplierAccount: string;
  Manufacturer: string;
  ReorderNumber: string;
  ItemType: string;
  RangeStatus: string;
  AvgCost: number;
  LastPOCost: number;
  Price: number;
  ForeignCost: number;
  MSRP: number;
  CloseoutPrice: number;
  MarginP: number;
  Age: string;
  SOHQty: number;
  CommittedQty: number;
  SOTWH: number;
  SOHStoreQty: number;
  SoldLWQty: number;
  SoldLWRetail: number;
  SoldLMQty: number;
  SoldLMRetail: number;
  SoldYTDQty: number;
  SoldYTDRetail: number;
  MaxInstockL4W: number;
  SOHWOC: number;
  CommittedWOC: number;
  LeadCompName: string;
  LeadCompPrice: number;
  PriceChangeType: string;
  NewSellPrice: number;
  BuyerComment: any;
  Approved: number;
  DateJSON: any;
  WeekOfYear: number;
}

interface BuyerFilterObj {
  Buyer: string;
  Department: string;
  Category: string;
  SubCategory: string;
}

interface FilteredBuyerFilterObj {
  Buyer: string[];
  Department: string[];
  Category: string[];
  SubCategory: string[];
}

interface ItemFilterObj {
  FieldName: string;
  Value: string;
}

interface SupplierFilterObj {
  SupplierGroup: string;
  Supplier: string;
}

interface MiscFilterObj {
  Manufacturer: string;
}

interface HeaderRowObj {
  Title: string;
  AllowSort: boolean;
}

function PriceChange() {
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [PriceChangeItemList, setPriceChangeItemList] = useState<PriceChangeItemObj[]>([]);
  const [IsGridLoading, setIsGridLoading] = useState(false);
  const [Message, setMessage] = useState<string>("");

  const [ItemFilterList, setItemFilterList] = useState<ItemFilterObj[]>([]);
  const [BuyerFilterList, setBuyerFilterList] = useState<BuyerFilterObj[]>([]);
  const [FilteredBuyerFilterList, setFilteredBuyerFilterList] = useState<FilteredBuyerFilterObj>({
    Buyer: [],
    Department: [],
    Category: [],
    SubCategory: [],
  });
  const [SupplierFilterList, setSupplierFilterList] = useState<SupplierFilterObj[]>([]);
  const [FilteredSupplierFilterList, setFilteredSupplierFilterList] = useState<SupplierFilterObj[]>(
    []
  );
  const [MiscFilterList, setMiscFilterList] = useState<MiscFilterObj[]>([]);
  const [PriceChangeFilterList, setPriceChangeFilterList] = useState<ItemFilterObj[]>([]);
  const [SelectedItemPlanningList, setSelectedItemPlanningList] = useState<string[]>([]);
  const [SelectedItemTypeList, setSelectedItemTypeList] = useState<string[]>([]);
  const [SelectedItemSourceList, setSelectedItemSourceList] = useState<string[]>([]);
  const [SelectedRangeStatusList, setSelectedRangeStatusList] = useState<string[]>([]);
  const [SelectedMarginBandList, setSelectedMarginBandList] = useState<string[]>([]);
  const [SelectedPricePointList, setSelectedPricePointList] = useState<string[]>([]);
  const [SelectedAgeList, setSelectedAgeList] = useState<string[]>([]);
  const [SelectedCoverStatusSOHList, setSelectedCoverStatusSOHList] = useState<string[]>([]);
  const [SelectedCoverStatusCommittedList, setSelectedCoverStatusCommittedList] = useState<
    string[]
  >([]);
  const [SelectedCompetitorList, setSelectedCompetitorList] = useState<string[]>([]);
  const [SelectedBuyerList, setSelectedBuyerList] = useState<string[]>([]);
  const [SelectedDepartmentList, setSelectedDepartmentList] = useState<string[]>([]);
  const [SelectedCategoryList, setSelectedCategoryList] = useState<string[]>([]);
  const [SelectedSubCategoryList, setSelectedSubCategoryList] = useState<string[]>([]);
  const [SelectedSupplierGroupList, setSelectedSupplierGroupList] = useState<string[]>([]);
  const [SelectedSupplierList, setSelectedSupplierList] = useState<string[]>([]);
  const [SelectedManufacturerList, setSelectedManufacturerList] = useState<string[]>([]);
  const [SelectedNPDStatusList, setSelectedNPDStatusList] = useState<string[]>([]);
  const [SelectedNPDMarketShareList, setSelectedNPDMarketShareList] = useState<string[]>([]);
  const [SelectedInstockStatusList, setSelectedInstockStatusList] = useState<string[]>([]);
  const [SelectedSellThroughList, setSelectedSellThroughList] = useState<string[]>([]);
  const [SelectedRRForecastBandList, setSelectedRRForecastBandList] = useState<string[]>([]);
  const [SelectedTradingForecastBandList, setSelectedTradingForecastBandList] = useState<string[]>(
    []
  );
  const [SelectedH2HSalesBandList, setSelectedH2HSalesBandList] = useState<string[]>([]);
  const [SelectedPriceChangeList, setSelectedPriceChangeList] = useState<string[]>([]);
  const [SelectedPriceChangeTypeList, setSelectedPriceChangeTypeList] = useState<string[]>([]);
  const [SelectedCommentList, setSelectedCommentList] = useState<string[]>([]);
  const [SelectedApprovedList, setSelectedApprovedList] = useState<string[]>([]);
  const [SelectedWeekNumberList, setSelectedWeekNumberList] = useState<string[]>([]);

  const [SelectedItemList, setSelectedItemList] = useState<string[]>([]);

  const [batchID, setBatchID] = useState<string>("");

  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortIcon, setSortIcon] = useState<IconDefinition>(solid("sort"));

  const [clearTriggered, setClearTriggered] = useState<boolean>(false);

  const [SearchDescription, setSearchDescription] = useState<string>("");

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showSort, setShowSort] = useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [showCustomFilter, setShowCustomFilter] = useState<boolean>(false);
  const [showAddItems, setShowAddItems] = useState<boolean>(false);
  const [showUserVerify, setShowUserVerify] = useState<boolean>(false);
  const [showProcess, setShowProcess] = useState<boolean>(false);
  const [showBuyerSummary, setShowBuyerSummary] = useState<boolean>(false);
  const [verifiedUser, setVerifiedUser] = useState<boolean>(false);
  // const [showComments, setShowComments] = useState<boolean>(false);
  const [enableCustomFilter, setEnableCustomFilter] = useState<boolean>(false);

  const [ProductView, setProductView] = useState<string>("large");

  const clearButtonUseful = () => {
    return !(
      SelectedItemPlanningList.length === 0 &&
      SelectedItemTypeList.length === 0 &&
      SelectedItemSourceList.length === 0 &&
      SelectedRangeStatusList.length === 0 &&
      SelectedMarginBandList.length === 0 &&
      SelectedPricePointList.length === 0 &&
      SelectedAgeList.length === 0 &&
      SelectedCoverStatusSOHList.length === 0 &&
      SelectedCoverStatusCommittedList.length === 0 &&
      SelectedCompetitorList.length === 0 &&
      SelectedBuyerList.length === 0 &&
      SelectedDepartmentList.length === 0 &&
      SelectedCategoryList.length === 0 &&
      SelectedSubCategoryList.length === 0 &&
      SelectedSupplierGroupList.length === 0 &&
      SelectedSupplierList.length === 0 &&
      SelectedManufacturerList.length === 0 &&
      SelectedItemList.length === 0 &&
      SelectedNPDStatusList.length === 0 &&
      SelectedNPDMarketShareList.length === 0 &&
      SelectedInstockStatusList.length === 0 &&
      SelectedSellThroughList.length === 0 &&
      SelectedRRForecastBandList.length === 0 &&
      SelectedTradingForecastBandList.length === 0 &&
      SelectedH2HSalesBandList.length === 0 &&
      SelectedPriceChangeList.length === 0 &&
      SelectedPriceChangeTypeList.length === 0 &&
      SelectedCommentList.length === 0 &&
      SelectedApprovedList.length === 0 &&
      SelectedWeekNumberList.length === 0 &&
      batchID === ""
    );
  };

  const getFilterList = async () => {
    const res = await runFetch(spURL_POST + "Portal_ItemList_Filter_Get", getReqOpt_POST("[{}]"));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else if (resObj.status === "OK!") {
      const spResponse: any = JSON.parse(resObj.response);
      //const spResponse: any = resObj.response;
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemFilterList(
          spResponse.Table.map((item: ItemFilterObj) => ({
            FieldName: item.FieldName,
            Value: item.Value,
          }))
        );
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setBuyerFilterList(
          spResponse.Table1.map((item: BuyerFilterObj) => ({
            Buyer: item.Buyer,
            Department: item.Department,
            Category: item.Category,
            SubCategory: item.SubCategory,
          }))
        );
        setFilteredBuyerFilterList({
          Buyer: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Buyer)
          ),
          Department: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Department)
          ),
          Category: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Category)
          ),
          SubCategory: getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.SubCategory)
          ),
        });

        // we get items here
        // assuming buyer filter is always here
        // we do it here to load item list with logged in buyer
        if (isInitialLoad) {
          let initialBuyerArr = getUniqueElementsOfArray(
            spResponse.Table1.map((item: BuyerFilterObj) => item.Buyer).filter(
              (buyer: string, index: number) => {
                return buyer === sessionStorage.getItem("userid")?.toUpperCase();
              }
            )
          );

          setSelectedBuyerList(initialBuyerArr);
          getItems(
            batchID,
            JSON.stringify([
              { FilterName: "ITEM PLANNING", FilterValue: SelectedItemPlanningList },
              { FilterName: "ITEM SOURCE", FilterValue: SelectedItemSourceList },
              { FilterName: "ITEM TYPE", FilterValue: SelectedItemTypeList },
              { FilterName: "RANGE STATUS", FilterValue: SelectedRangeStatusList },
              { FilterName: "MARGIN BAND", FilterValue: SelectedMarginBandList },
              { FilterName: "PRICE POINT", FilterValue: SelectedPricePointList },
              { FilterName: "AGE", FilterValue: SelectedAgeList },
              { FilterName: "COVER STATUS SOH", FilterValue: SelectedCoverStatusSOHList },
              {
                FilterName: "COVER STATUS COMMITTED",
                FilterValue: SelectedCoverStatusCommittedList,
              },
              { FilterName: "COMPETITOR", FilterValue: SelectedCompetitorList },
              { FilterName: "NPD STATUS", FilterValue: SelectedNPDStatusList },
              { FilterName: "NPD MARKET SHARE", FilterValue: SelectedNPDMarketShareList },
              { FilterName: "INSTOCK STATUS", FilterValue: SelectedInstockStatusList },
              { FilterName: "SELL THROUGH", FilterValue: SelectedSellThroughList },
              { FilterName: "RR FORECAST BAND", FilterValue: SelectedRRForecastBandList },
              { FilterName: "TRADING FORECAST BAND", FilterValue: SelectedTradingForecastBandList },
              { FilterName: "H2H SALES BAND", FilterValue: SelectedH2HSalesBandList },
              { FilterName: "BUYER", FilterValue: initialBuyerArr },
              { FilterName: "DEPARTMENT", FilterValue: SelectedDepartmentList },
              { FilterName: "CATEGORY", FilterValue: SelectedCategoryList },
              { FilterName: "SUBCATEGORY", FilterValue: SelectedSubCategoryList },
              { FilterName: "SUPPLIER GROUP", FilterValue: SelectedSupplierGroupList },
              { FilterName: "SUPPLIER", FilterValue: SelectedSupplierList },
              { FilterName: "MANUFACTURER", FilterValue: SelectedManufacturerList },
              { FilterName: "PRICE CHANGED", FilterValue: SelectedPriceChangeList },
              { FilterName: "PRICE CHANGE TYPE", FilterValue: SelectedPriceChangeTypeList },
              { FilterName: "COMMENT", FilterValue: SelectedCommentList },
              { FilterName: "APPROVED", FilterValue: SelectedApprovedList },
              { FilterName: "WEEK NUMBER", FilterValue: SelectedWeekNumberList },
              { FilterName: "SEARCH", FilterValue: SearchDescription },
              { FilterName: "SORT", FilterValue: sortColumn + "-" + sortOrder },
              { FilterName: "CUSTOM FILTER", FilterValue: "" },
            ])
          );
        }
      }
      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setSupplierFilterList(
          spResponse.Table2.map((item: SupplierFilterObj) => ({
            SupplierGroup: item.SupplierGroup,
            Supplier: item.Supplier,
          }))
        );
        setFilteredSupplierFilterList(
          spResponse.Table2.map((item: SupplierFilterObj) => ({
            SupplierGroup: item.SupplierGroup,
            Supplier: item.Supplier,
          }))
        );
      }
      if (spResponse.Table3 !== undefined && spResponse.Table3.length > 0) {
        setMiscFilterList(
          spResponse.Table3.map((item: MiscFilterObj) => ({
            Manufacturer: item.Manufacturer,
          }))
        );
      }
      if (spResponse.Table4 !== undefined && spResponse.Table4.length > 0) {
        setPriceChangeFilterList(
          spResponse.Table4.map((item: ItemFilterObj) => ({
            FieldName: item.FieldName,
            Value: item.Value,
          }))
        );
      }
    }
  };

  const getItems = useCallback(
    async (_batchid: string = batchID, _json: string = "") => {
      setMessage("");
      setIsGridLoading(true);

      setSelectedItemList([]);

      const inputJSON: string =
        _json === ""
          ? JSON.stringify([
              { FilterName: "ITEM PLANNING", FilterValue: SelectedItemPlanningList },
              { FilterName: "ITEM SOURCE", FilterValue: SelectedItemSourceList },
              { FilterName: "ITEM TYPE", FilterValue: SelectedItemTypeList },
              { FilterName: "RANGE STATUS", FilterValue: SelectedRangeStatusList },
              { FilterName: "MARGIN BAND", FilterValue: SelectedMarginBandList },
              { FilterName: "PRICE POINT", FilterValue: SelectedPricePointList },
              { FilterName: "AGE", FilterValue: SelectedAgeList },
              { FilterName: "COVER STATUS SOH", FilterValue: SelectedCoverStatusSOHList },
              {
                FilterName: "COVER STATUS COMMITTED",
                FilterValue: SelectedCoverStatusCommittedList,
              },
              { FilterName: "COMPETITOR", FilterValue: SelectedCompetitorList },
              { FilterName: "NPD STATUS", FilterValue: SelectedNPDStatusList },
              { FilterName: "NPD MARKET SHARE", FilterValue: SelectedNPDMarketShareList },
              { FilterName: "INSTOCK STATUS", FilterValue: SelectedInstockStatusList },
              { FilterName: "SELL THROUGH", FilterValue: SelectedSellThroughList },
              { FilterName: "RR FORECAST BAND", FilterValue: SelectedRRForecastBandList },
              { FilterName: "TRADING FORECAST BAND", FilterValue: SelectedTradingForecastBandList },
              { FilterName: "H2H SALES BAND", FilterValue: SelectedH2HSalesBandList },
              { FilterName: "BUYER", FilterValue: SelectedBuyerList },
              { FilterName: "DEPARTMENT", FilterValue: SelectedDepartmentList },
              { FilterName: "CATEGORY", FilterValue: SelectedCategoryList },
              { FilterName: "SUBCATEGORY", FilterValue: SelectedSubCategoryList },
              { FilterName: "SUPPLIER GROUP", FilterValue: SelectedSupplierGroupList },
              { FilterName: "SUPPLIER", FilterValue: SelectedSupplierList },
              { FilterName: "MANUFACTURER", FilterValue: SelectedManufacturerList },
              { FilterName: "PRICE CHANGED", FilterValue: SelectedPriceChangeList },
              { FilterName: "PRICE CHANGE TYPE", FilterValue: SelectedPriceChangeTypeList },
              { FilterName: "COMMENT", FilterValue: SelectedCommentList },
              { FilterName: "APPROVED", FilterValue: SelectedApprovedList },
              { FilterName: "WEEK NUMBER", FilterValue: SelectedWeekNumberList },
              { FilterName: "SEARCH", FilterValue: SearchDescription },
              { FilterName: "SORT", FilterValue: sortColumn + "-" + sortOrder },
              { FilterName: "CUSTOM FILTER", FilterValue: _batchid },
            ])
          : _json;

      const res = await runFetch(spURL_POST + "Portal_PriceChange_Get", getReqOpt_POST(inputJSON));

      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setMessage(resObj.exception);
      } else if (resObj.status === "OK!") {
        // response === "" means no data found in table
        if (resObj.response === "") {
          setPriceChangeItemList([]);
        } else {
          const spResponse: any = JSON.parse(resObj.response);
          //const spResponse: any = resObj.response;

          if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
            setPriceChangeItemList(
              spResponse.Table.map((item: PriceChangeItemObj) => ({
                ItemNo: item.ItemNo,
                ImgURL: item.ImgURL,
                Description: item.Description,
                SupplierAccount: item.SupplierAccount,
                Manufacturer: item.Manufacturer,
                ReorderNumber: item.ReorderNumber,
                ItemType: item.ItemType,
                RangeStatus: item.RangeStatus,
                AvgCost: item.AvgCost,
                LastPOCost: item.LastPOCost,
                Price: item.Price,
                ForeignCost: item.ForeignCost,
                MSRP: item.MSRP,
                CloseoutPrice: item.CloseoutPrice,
                MarginP: item.MarginP,
                Age: item.Age,
                SOHQty: item.SOHQty,
                CommittedQty: item.CommittedQty,
                SOTWH: item.SOTWH,
                SOHStoreQty: item.SOHStoreQty,
                SoldLWQty: item.SoldLWQty,
                SoldLWRetail: item.SoldLWRetail,
                SoldLMQty: item.SoldLMQty,
                SoldLMRetail: item.SoldLMRetail,
                SoldYTDQty: item.SoldYTDQty,
                SoldYTDRetail: item.SoldYTDRetail,
                MaxInstockL4W: item.MaxInstockL4W,
                SOHWOC: item.SOHWOC,
                CommittedWOC: item.CommittedWOC,
                LeadCompName: item.LeadCompName,
                LeadCompPrice: item.LeadCompPrice,
                PriceChangeType: item.PriceChangeType,
                NewSellPrice: item.NewSellPrice,
                BuyerComment: JSON.parse(item.BuyerComment || "[]"),
                Approved: item.Approved,
                DateJSON: JSON.parse(item.DateJSON || "[]"),
                WeekOfYear: item.WeekOfYear,
              }))
            );
          }
        }
      }

      setIsGridLoading(false);
    },
    [
      SelectedItemPlanningList,
      SelectedItemSourceList,
      SelectedItemTypeList,
      SelectedRangeStatusList,
      SelectedMarginBandList,
      SelectedPricePointList,
      SelectedAgeList,
      SelectedCoverStatusSOHList,
      SelectedCoverStatusCommittedList,
      SelectedCompetitorList,
      SelectedNPDStatusList,
      SelectedNPDMarketShareList,
      SelectedInstockStatusList,
      SelectedSellThroughList,
      SelectedRRForecastBandList,
      SelectedTradingForecastBandList,
      SelectedH2HSalesBandList,
      SelectedBuyerList,
      SelectedDepartmentList,
      SelectedCategoryList,
      SelectedSubCategoryList,
      SelectedSupplierGroupList,
      SelectedSupplierList,
      SelectedPriceChangeList,
      SelectedPriceChangeTypeList,
      SelectedCommentList,
      SelectedApprovedList,
      SelectedWeekNumberList,
      SearchDescription,
      sortColumn,
      sortOrder,
      batchID,
    ]
  );

  // const getItems = async (_batchid: string) => {
  //   setMessage("");
  //   setIsGridLoading(true);

  //   setSelectedItemList([]);

  //   const inputJSON: string = JSON.stringify([
  //     { FilterName: "ITEM PLANNING", FilterValue: SelectedItemPlanningList },
  //     { FilterName: "ITEM SOURCE", FilterValue: SelectedItemSourceList },
  //     { FilterName: "ITEM TYPE", FilterValue: SelectedItemTypeList },
  //     { FilterName: "RANGE STATUS", FilterValue: SelectedRangeStatusList },
  //     { FilterName: "MARGIN BAND", FilterValue: SelectedMarginBandList },
  //     { FilterName: "PRICE POINT", FilterValue: SelectedPricePointList },
  //     { FilterName: "AGE", FilterValue: SelectedAgeList },
  //     { FilterName: "COVER STATUS SOH", FilterValue: SelectedCoverStatusSOHList },
  //     { FilterName: "COVER STATUS COMMITTED", FilterValue: SelectedCoverStatusCommittedList },
  //     { FilterName: "COMPETITOR", FilterValue: SelectedCompetitorList },
  //     { FilterName: "NPD STATUS", FilterValue: SelectedNPDStatusList },
  //     { FilterName: "NPD MARKET SHARE", FilterValue: SelectedNPDMarketShareList },
  //     { FilterName: "INSTOCK STATUS", FilterValue: SelectedInstockStatusList },
  //     { FilterName: "SELL THROUGH", FilterValue: SelectedSellThroughList },
  //     { FilterName: "RR FORECAST BAND", FilterValue: SelectedRRForecastBandList },
  //     { FilterName: "TRADING FORECAST BAND", FilterValue: SelectedTradingForecastBandList },
  //     { FilterName: "H2H SALES BAND", FilterValue: SelectedH2HSalesBandList },
  //     { FilterName: "BUYER", FilterValue: SelectedBuyerList },
  //     { FilterName: "DEPARTMENT", FilterValue: SelectedDepartmentList },
  //     { FilterName: "CATEGORY", FilterValue: SelectedCategoryList },
  //     { FilterName: "SUBCATEGORY", FilterValue: SelectedSubCategoryList },
  //     { FilterName: "SUPPLIER GROUP", FilterValue: SelectedSupplierGroupList },
  //     { FilterName: "SUPPLIER", FilterValue: SelectedSupplierList },
  //     { FilterName: "MANUFACTURER", FilterValue: SelectedManufacturerList },
  //     { FilterName: "PRICE CHANGED", FilterValue: SelectedPriceChangeList },
  //     { FilterName: "PRICE CHANGE TYPE", FilterValue: SelectedPriceChangeTypeList },
  //     { FilterName: "COMMENT", FilterValue: SelectedCommentList },
  //     { FilterName: "APPROVED", FilterValue: SelectedApprovedList },
  //     { FilterName: "WEEK NUMBER", FilterValue: SelectedWeekNumberList },
  //     { FilterName: "SEARCH", FilterValue: SearchDescription },
  //     { FilterName: "SORT", FilterValue: sortColumn + "-" + sortOrder },
  //     { FilterName: "CUSTOM FILTER", FilterValue: _batchid },
  //   ]);

  //   const res = await runFetch(spURL_POST + "Portal_PriceChange_Get", getReqOpt_POST(inputJSON));

  //   const resObj = JSON.parse(res);
  //   if (resObj.status === "ERROR!") {
  //     setMessage(resObj.exception);
  //   } else if (resObj.status === "OK!") {
  //     // response === "" means no data found in table
  //     if (resObj.response === "") {
  //       setPriceChangeItemList([]);
  //     } else {
  //       const spResponse: any = JSON.parse(resObj.response);
  //       //const spResponse: any = resObj.response;

  //       if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
  //         setPriceChangeItemList(
  //           spResponse.Table.map((item: PriceChangeItemObj) => ({
  //             ItemNo: item.ItemNo,
  //             ImgURL: item.ImgURL,
  //             Description: item.Description,
  //             SupplierAccount: item.SupplierAccount,
  //             Manufacturer: item.Manufacturer,
  //             ReorderNumber: item.ReorderNumber,
  //             ItemType: item.ItemType,
  //             RangeStatus: item.RangeStatus,
  //             AvgCost: item.AvgCost,
  //             LastPOCost: item.LastPOCost,
  //             Price: item.Price,
  //             ForeignCost: item.ForeignCost,
  //             MSRP: item.MSRP,
  //             CloseoutPrice: item.CloseoutPrice,
  //             MarginP: item.MarginP,
  //             Age: item.Age,
  //             SOHQty: item.SOHQty,
  //             CommittedQty: item.CommittedQty,
  //             SOTWH: item.SOTWH,
  //             SOHStoreQty: item.SOHStoreQty,
  //             SoldLWQty: item.SoldLWQty,
  //             SoldLWRetail: item.SoldLWRetail,
  //             SoldLMQty: item.SoldLMQty,
  //             SoldLMRetail: item.SoldLMRetail,
  //             SoldYTDQty: item.SoldYTDQty,
  //             SoldYTDRetail: item.SoldYTDRetail,
  //             MaxInstockL4W: item.MaxInstockL4W,
  //             SOHWOC: item.SOHWOC,
  //             CommittedWOC: item.CommittedWOC,
  //             LeadCompName: item.LeadCompName,
  //             LeadCompPrice: item.LeadCompPrice,
  //             PriceChangeType: item.PriceChangeType,
  //             NewSellPrice: item.NewSellPrice,
  //             BuyerComment: JSON.parse(item.BuyerComment || "[]"),
  //             Approved: item.Approved,
  //             DateJSON: JSON.parse(item.DateJSON || "[]"),
  //             WeekOfYear: item.WeekOfYear,
  //           }))
  //         );
  //       }
  //     }
  //   }

  //   setIsGridLoading(false);
  // };

  const onFilterItemListChange = async (
    _filtername: string,
    setFunction: Function,
    list: string[]
  ) => {
    setFunction(list);

    let tmpArr = [
      { FilterName: "ITEM PLANNING", FilterValue: SelectedItemPlanningList },
      { FilterName: "ITEM SOURCE", FilterValue: SelectedItemSourceList },
      { FilterName: "ITEM TYPE", FilterValue: SelectedItemTypeList },
      { FilterName: "RANGE STATUS", FilterValue: SelectedRangeStatusList },
      { FilterName: "MARGIN BAND", FilterValue: SelectedMarginBandList },
      { FilterName: "PRICE POINT", FilterValue: SelectedPricePointList },
      { FilterName: "AGE", FilterValue: SelectedAgeList },
      { FilterName: "COVER STATUS SOH", FilterValue: SelectedCoverStatusSOHList },
      { FilterName: "COVER STATUS COMMITTED", FilterValue: SelectedCoverStatusCommittedList },
      { FilterName: "COMPETITOR", FilterValue: SelectedCompetitorList },
      { FilterName: "NPD STATUS", FilterValue: SelectedNPDStatusList },
      { FilterName: "NPD MARKET SHARE", FilterValue: SelectedNPDMarketShareList },
      { FilterName: "INSTOCK STATUS", FilterValue: SelectedInstockStatusList },
      { FilterName: "SELL THROUGH", FilterValue: SelectedSellThroughList },
      { FilterName: "RR FORECAST BAND", FilterValue: SelectedRRForecastBandList },
      { FilterName: "TRADING FORECAST BAND", FilterValue: SelectedTradingForecastBandList },
      { FilterName: "H2H SALES BAND", FilterValue: SelectedH2HSalesBandList },
      { FilterName: "BUYER", FilterValue: SelectedBuyerList },
      { FilterName: "DEPARTMENT", FilterValue: SelectedDepartmentList },
      { FilterName: "CATEGORY", FilterValue: SelectedCategoryList },
      { FilterName: "SUBCATEGORY", FilterValue: SelectedSubCategoryList },
      { FilterName: "SUPPLIER GROUP", FilterValue: SelectedSupplierGroupList },
      { FilterName: "SUPPLIER", FilterValue: SelectedSupplierList },
      { FilterName: "MANUFACTURER", FilterValue: SelectedManufacturerList },
      { FilterName: "PRICE CHANGED", FilterValue: SelectedPriceChangeList },
      { FilterName: "PRICE CHANGE TYPE", FilterValue: SelectedPriceChangeTypeList },
      { FilterName: "COMMENT", FilterValue: SelectedCommentList },
      { FilterName: "APPROVED", FilterValue: SelectedApprovedList },
      { FilterName: "WEEK NUMBER", FilterValue: SelectedWeekNumberList },
      { FilterName: "SEARCH", FilterValue: SearchDescription },
      { FilterName: "SORT", FilterValue: sortColumn + "-" + sortOrder },
      { FilterName: "CUSTOM FILTER", FilterValue: "" },
    ];

    tmpArr = tmpArr.map((filter) => {
      if (filter.FilterName === _filtername.toUpperCase()) {
        return {
          FilterName: filter.FilterName,
          FilterValue: list,
        };
      }

      return filter;
    });

    const inputJSON: string = JSON.stringify(tmpArr);
    getItems(batchID, inputJSON);
  };

  const getUniqueElementsOfArray = (arr: any[]) => {
    return arr.filter(function (value, index, array) {
      return array.indexOf(value) === index;
    });
  };

  function getFilteredBuyerFilterList() {
    // buyer always show so we just show all buyers
    let buyerList = getUniqueElementsOfArray(BuyerFilterList.map((item) => item.Buyer));

    // get departments attached to the buyers selected
    let departmentList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Buyer: item.Buyer,
        Department: item.Department,
      }))
        .filter((item) => SelectedBuyerList.length === 0 || SelectedBuyerList.includes(item.Buyer))
        .map((item) => item.Department)
    );

    // get categories attached to the departments selected
    let categoryList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Department: item.Department,
        Category: item.Category,
      }))
        .filter(
          (item) =>
            SelectedDepartmentList.length === 0 || SelectedDepartmentList.includes(item.Department)
        )
        .map((item) => item.Category)
    );

    // get subcategories attached to the categories selected
    let subCategoryList = getUniqueElementsOfArray(
      BuyerFilterList.map((item) => ({
        Category: item.Category,
        SubCategory: item.SubCategory,
      }))
        .filter(
          (item) =>
            SelectedCategoryList.length === 0 || SelectedCategoryList.includes(item.Category)
        )
        .map((item) => item.SubCategory)
    );

    setFilteredBuyerFilterList({
      Buyer: buyerList,
      Department: departmentList,
      Category: categoryList,
      SubCategory: subCategoryList,
    });
  }

  function getFilteredSupplierFilterList() {
    let NewList = SupplierFilterList.filter(
      (item) =>
        (IsArrayEmpty(SelectedSupplierGroupList) ||
          SelectedSupplierGroupList.includes(item.SupplierGroup)) &&
        (IsArrayEmpty(SelectedSupplierList) || SelectedSupplierList.includes(item.Supplier))
    );

    setFilteredSupplierFilterList(NewList);
  }

  // const handleClick_Apply = () => {
  //   getFilteredBuyerFilterList();
  //   getFilteredSupplierFilterList();
  // };

  const resetFilter = () => {
    getFilterList();

    const checkboxes = document.getElementsByClassName("form-check-input");

    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i] as HTMLInputElement;
      checkbox.checked = false;
    }

    setBatchID("");
    setSelectedItemPlanningList([]);
    setSelectedItemSourceList([]);
    setSelectedItemTypeList([]);
    setSelectedRangeStatusList([]);
    setSelectedMarginBandList([]);
    setSelectedPricePointList([]);
    setSelectedAgeList([]);
    setSelectedCoverStatusSOHList([]);
    setSelectedCoverStatusCommittedList([]);
    setSelectedCompetitorList([]);
    setSelectedBuyerList([]);
    setSelectedDepartmentList([]);
    setSelectedCategoryList([]);
    setSelectedSubCategoryList([]);
    setSelectedSupplierGroupList([]);
    setSelectedSupplierList([]);
    setSelectedManufacturerList([]);
    setSelectedItemList([]);
    setSelectedNPDStatusList([]);
    setSelectedNPDMarketShareList([]);
    setSelectedInstockStatusList([]);
    setSelectedSellThroughList([]);
    setSelectedRRForecastBandList([]);
    setSelectedTradingForecastBandList([]);
    setSelectedH2HSalesBandList([]);
    setSelectedPriceChangeList([]);
    setSelectedPriceChangeTypeList([]);
    setSelectedCommentList([]);
    setSelectedApprovedList([]);
    setSelectedWeekNumberList([]);
  };

  const handleClick_Clear = () => {
    setMessage("");
    if (clearButtonUseful()) {
      resetFilter();
      setClearTriggered(!clearTriggered);
      setEnableCustomFilter(false);
      getItems("");
    }
  };

  const handleClick_CustomFilter = () => {
    setMessage("");
    setShowCustomFilter(!showCustomFilter);
  };

  const handleClick_AddItem = () => {
    setMessage("");
    setShowAddItems(!showAddItems);
  };

  const handleClick_Approve = () => {
    setMessage("");
    if (SelectedItemList.length !== 0) {
      if (!verifiedUser) {
        setShowUserVerify(!showUserVerify);
      } else {
        ApproveItems();
      }
    }
  };

  const handleClick_Process = () => {
    setMessage("");
    setShowProcess(!showProcess);
  };

  const handleClick_BuyerSummary = () => {
    setMessage("");
    setShowBuyerSummary(!showBuyerSummary);
  };

  const renderItemFilters = () => {
    const itemFilters = [
      {
        name: "Item Planning",
        selectFunction: setSelectedItemPlanningList,
        selectedItems: SelectedItemPlanningList,
      },
      {
        name: "Item Source",
        selectFunction: setSelectedItemSourceList,
        selectedItems: SelectedItemSourceList,
      },
      {
        name: "Item Type",
        selectFunction: setSelectedItemTypeList,
        selectedItems: SelectedItemTypeList,
      },
      {
        name: "Range Status",
        selectFunction: setSelectedRangeStatusList,
        selectedItems: SelectedRangeStatusList,
      },
      {
        name: "Margin Band",
        selectFunction: setSelectedMarginBandList,
        selectedItems: SelectedMarginBandList,
      },
      {
        name: "Price Point",
        selectFunction: setSelectedPricePointList,
        selectedItems: SelectedPricePointList,
      },
      { name: "Age", selectFunction: setSelectedAgeList, selectedItems: SelectedAgeList },
      {
        name: "CoverStatus SOH",
        selectFunction: setSelectedCoverStatusSOHList,
        selectedItems: SelectedCoverStatusSOHList,
      },
      {
        name: "CoverStatus Committed",
        selectFunction: setSelectedCoverStatusCommittedList,
        selectedItems: SelectedCoverStatusCommittedList,
      },
      {
        name: "Competitor",
        selectFunction: setSelectedCompetitorList,
        selectedItems: SelectedCompetitorList,
      },
      {
        name: "NPD Status",
        selectFunction: setSelectedNPDStatusList,
        selectedItems: SelectedNPDStatusList,
      },
      {
        name: "NPD Market Share",
        selectFunction: setSelectedNPDMarketShareList,
        selectedItems: SelectedNPDMarketShareList,
      },
      {
        name: "Instock Status",
        selectFunction: setSelectedInstockStatusList,
        selectedItems: SelectedInstockStatusList,
      },
      {
        name: "Sell Through",
        selectFunction: setSelectedSellThroughList,
        selectedItems: SelectedSellThroughList,
      },
      {
        name: "RR Forecast Band",
        selectFunction: setSelectedRRForecastBandList,
        selectedItems: SelectedRRForecastBandList,
      },
      {
        name: "Trading Forecast Band",
        selectFunction: setSelectedTradingForecastBandList,
        selectedItems: SelectedTradingForecastBandList,
      },
      {
        name: "H2H Sales Band",
        selectFunction: setSelectedH2HSalesBandList,
        selectedItems: SelectedH2HSalesBandList,
      },
    ];

    return itemFilters.map((filter, index) => (
      <SmartFilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          ItemFilterList.filter((item) => item.FieldName === StringWithNoSpace(filter.name)).map(
            (item) => item.Value
          )
        )}
        FilterItems={getUniqueElementsOfArray(
          ItemFilterList.filter((item) => item.FieldName === StringWithNoSpace(filter.name)).map(
            (item) => item.Value
          )
        )}
        SelectedItems={filter.selectedItems}
        OnFilterChange={(list) =>
          onFilterItemListChange(filter.name.toUpperCase(), filter.selectFunction, list)
        }
        clearTriggered={clearTriggered}
      />
    ));
  };

  const renderBuyerFilters = () => {
    const buyerFilters = [
      { name: "Buyer", selectFunction: setSelectedBuyerList, selectedItems: SelectedBuyerList },
      {
        name: "Department",
        selectFunction: setSelectedDepartmentList,
        selectedItems: SelectedDepartmentList,
      },
      {
        name: "Category",
        selectFunction: setSelectedCategoryList,
        selectedItems: SelectedCategoryList,
      },
      {
        name: "SubCategory",
        selectFunction: setSelectedSubCategoryList,
        selectedItems: SelectedSubCategoryList,
      },
    ];

    return buyerFilters.map((filter, index) => (
      <SmartFilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          BuyerFilterList.map((item) => GetValueOfKey(item, filter.name))
        )}
        FilterItems={GetValueOfKey(FilteredBuyerFilterList, filter.name)}
        SelectedItems={filter.selectedItems}
        OnFilterChange={(list) =>
          onFilterItemListChange(filter.name.toUpperCase(), filter.selectFunction, list)
        }
        clearTriggered={clearTriggered}
      />
    ));
  };

  const renderSupplierFilters = () => {
    const supplierFilters = [
      {
        name: "Supplier Group",
        selectFunction: setSelectedSupplierGroupList,
        selectedItems: SelectedSupplierGroupList,
      },
      {
        name: "Supplier",
        selectFunction: setSelectedSupplierList,
        selectedItems: SelectedSupplierList,
      },
    ];

    return supplierFilters.map((filter, index) => (
      <SmartFilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          SupplierFilterList.map((item) => GetValueOfKey(item, StringWithNoSpace(filter.name)))
        )}
        FilterItems={getUniqueElementsOfArray(
          FilteredSupplierFilterList.map((item) =>
            GetValueOfKey(item, StringWithNoSpace(filter.name))
          )
        )}
        SelectedItems={filter.selectedItems}
        OnFilterChange={(list) =>
          onFilterItemListChange(filter.name.toUpperCase(), filter.selectFunction, list)
        }
        clearTriggered={clearTriggered}
      />
    ));
  };

  const renderMiscFilters = () => {
    const itemFilters = [
      {
        name: "Manufacturer",
        selectFunction: setSelectedManufacturerList,
        selectedItems: SelectedManufacturerList,
      },
    ];

    return itemFilters.map((filter, index) => (
      <SmartFilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          MiscFilterList.map((item) => GetValueOfKey(item, filter.name))
        )}
        FilterItems={getUniqueElementsOfArray(
          MiscFilterList.map((item) => GetValueOfKey(item, filter.name))
        )}
        SelectedItems={filter.selectedItems}
        OnFilterChange={(list) =>
          onFilterItemListChange(filter.name.toUpperCase(), filter.selectFunction, list)
        }
        clearTriggered={clearTriggered}
      />
    ));
  };

  const renderPriceChangeFilters = () => {
    const priceChangeFilters = [
      {
        name: "Price Changed",
        selectFunction: setSelectedPriceChangeList,
        selectedItems: SelectedPriceChangeList,
      },
      {
        name: "Price Change Type",
        selectFunction: setSelectedPriceChangeTypeList,
        selectedItems: SelectedPriceChangeTypeList,
      },
      {
        name: "Comment",
        selectFunction: setSelectedCommentList,
        selectedItems: SelectedCommentList,
      },
      {
        name: "Approved",
        selectFunction: setSelectedApprovedList,
        selectedItems: SelectedApprovedList,
      },
      {
        name: "Week Number",
        selectFunction: setSelectedWeekNumberList,
        selectedItems: SelectedWeekNumberList,
      },
    ];

    return priceChangeFilters.map((filter, index) => (
      <SmartFilterAccordion
        key={index}
        FilterName={filter.name}
        Items={getUniqueElementsOfArray(
          PriceChangeFilterList.filter(
            (item) => item.FieldName === StringWithNoSpace(filter.name)
          ).map((item) => item.Value)
        )}
        FilterItems={getUniqueElementsOfArray(
          PriceChangeFilterList.filter(
            (item) => item.FieldName === StringWithNoSpace(filter.name)
          ).map((item) => item.Value)
        )}
        SelectedItems={filter.selectedItems}
        OnFilterChange={(list) =>
          onFilterItemListChange(filter.name.toUpperCase(), filter.selectFunction, list)
        }
        clearTriggered={clearTriggered}
      />
    ));
  };

  const renderSort = () => {
    const sortArr = [
      { Title: "Default Sort", SortColumn: "CustomOrder", SortOrder: "ASC" },
      { Title: "Price Change: Low to High", SortColumn: "PriceChange", SortOrder: "ASC" },
      { Title: "Supplier Account", SortColumn: "SupplierAccount", SortOrder: "ASC" },
      { Title: "Sell Price: Low to High", SortColumn: "SellPrice", SortOrder: "ASC" },
      { Title: "Sell Price: High to Low", SortColumn: "SellPrice", SortOrder: "DESC" },
      { Title: "Last PO Cost", SortColumn: "LastPOCost", SortOrder: "ASC" },
      { Title: "Cost Price", SortColumn: "CostPrice", SortOrder: "ASC" },
      { Title: "Qty Sold L7D", SortColumn: "QtySoldL7D", SortOrder: "DESC" },
      { Title: "Qty Sold L52W", SortColumn: "QtySoldL52W", SortOrder: "DESC" },
      { Title: "Sold$ LW", SortColumn: "SoldLWD", SortOrder: "DESC" },
      { Title: "Sold$ LM", SortColumn: "SoldLMD", SortOrder: "DESC" },
      { Title: "Sold$ YTD", SortColumn: "SoldYTD", SortOrder: "DESC" },
      { Title: "Sold$ L52W", SortColumn: "SoldL52W", SortOrder: "DESC" },
      { Title: "Sold$ LY H2H", SortColumn: "LYH2H", SortOrder: "DESC" },
      { Title: "RR Forecast Retail", SortColumn: "RRForecastRetail", SortOrder: "DESC" },
      { Title: "Trading Forecast Retail", SortColumn: "TradingForecastRetail", SortOrder: "DESC" },
      { Title: "52W Forecast Retail", SortColumn: "52WForecastRetail", SortOrder: "DESC" },
      { Title: "RR Forecast: Low to High", SortColumn: "RRForecast", SortOrder: "ASC" },
      { Title: "RR Forecast: High to Low", SortColumn: "RRForecast", SortOrder: "DESC" },
      { Title: "Supplier Hold Retail", SortColumn: "SupplierHoldRetail", SortOrder: "DESC" },
      { Title: "Committed SOH $", SortColumn: "CommittedRetail", SortOrder: "DESC" },
      { Title: "Max Instock% L4W: Low to High", SortColumn: "MaxInstockL4W", SortOrder: "ASC" },
      { Title: "Max Instock% L4W: High to Low", SortColumn: "MaxInstockL4W", SortOrder: "DESC" },
      { Title: "Committed WOC: Low to High", SortColumn: "CommittedWOC", SortOrder: "ASC" },
      { Title: "Committed WOC: High to Low", SortColumn: "CommittedWOC", SortOrder: "DESC" },
    ];

    return sortArr.map((sortObj, index) => (
      <li
        key={index}
        className={`${
          sortObj.SortColumn + "-" + sortObj.SortOrder === sortColumn + "-" + sortOrder &&
          "selected"
        } btnHover`}
        data-sortcolumn={sortObj.SortColumn}
        data-sortorder={sortObj.SortOrder}
        onClick={() => {
          setSortColumn(sortObj.SortColumn);
          setSortOrder(sortObj.SortOrder);
          setShowSort(false); // hide sort offcanvas after selecting
          getItems();
        }}
      >
        {sortObj.Title}
      </li>
    ));
  };

  const handleClick_Search = () => {
    setMessage("");
    setShowSearch(!showSearch);
  };

  const handleClick_Filter = () => {
    setMessage("");
    setShowFilter(true);
  };

  const handleClick_Sort = () => {
    setMessage("");
    setShowSort(true);
  };

  const handleClick_Summary = () => {
    setMessage("");
    if (SelectedItemList.length !== 0) {
      setShowSummary(true);
    }
  };

  const handleClick_SelectAll = () => {
    setMessage("");
    const arrAllItems: string[] = PriceChangeItemList.map((item) => item.ItemNo);
    setSelectedItemList(arrAllItems);
  };

  const handleClick_SelectNone = () => {
    setMessage("");
    setSelectedItemList([]);
  };

  const toggleCardSelect = (itemno: string) => {
    // if itemno is in selected list
    setMessage("");

    let nextSelectedItemList: string[] = SelectedItemList;
    if (SelectedItemList.includes(itemno)) {
      nextSelectedItemList = nextSelectedItemList.filter((item) => item !== itemno);
    } else {
      nextSelectedItemList = nextSelectedItemList.concat(itemno);
    }

    setSelectedItemList(nextSelectedItemList);

    setPriceChangeItemList(
      PriceChangeItemList.map((item) => {
        if (item.ItemNo === itemno) {
          return {
            ...item,
          };
        }
        return item;
      })
    );
  };

  const changeNewSellPrice = (itemno: string, newprice: number) => {
    setMessage("");
    setPriceChangeItemList(
      PriceChangeItemList.map((item) => {
        if (item.ItemNo === itemno) {
          return {
            ...item,
            NewSellPrice: newprice,
            Approved: 0,
          };
        }
        return item;
      })
    );
    saveNewPrice(itemno, newprice);
  };

  const changeDate = (itemno: string, week: number) => {
    setMessage("");
    setPriceChangeItemList(
      PriceChangeItemList.map((item) => {
        if (item.ItemNo === itemno) {
          return {
            ...item,
            WeekOfYear: week,
            Approved: 0,
          };
        }
        return item;
      })
    );
    savePriceChangeWeek(itemno, week);
  };

  const saveNewPrice = async (itemno: string, newprice: number) => {
    const inputJSON: string = JSON.stringify([{ ItemNo: itemno, NewPrice: newprice }]);

    const res = await runFetch(spURL_POST + "Portal_PriceChange_Update", getReqOpt_POST(inputJSON));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    }
  };

  const savePriceChangeWeek = async (itemno: string, week: number) => {
    const inputJSON: string = JSON.stringify([{ ItemNo: itemno, Week: week }]);

    const res = await runFetch(
      spURL_POST + "Portal_PriceChange_Week_Update",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    }
  };

  const addNewItems = async (_batchid: string) => {
    const res = await runFetch(spURL_POST + "Portal_PriceChange_Add", getReqOpt_POST(_batchid));

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    }
  };

  const updatePriceChangeItemList = (itemno: string, newbuyercomment: string) => {
    setMessage("");
    setPriceChangeItemList(
      PriceChangeItemList.map((prev: PriceChangeItemObj) => {
        if (prev.ItemNo === itemno) {
          return {
            ...prev,
            BuyerComment: JSON.parse(newbuyercomment || "[]"),
          };
        }
        return prev;
      })
    );
  };

  const removeComment = async (itemno: string) => {
    setMessage("");
    setPriceChangeItemList(
      PriceChangeItemList.map((prev: PriceChangeItemObj) => {
        if (prev.ItemNo === itemno) {
          return {
            ...prev,
            BuyerComment: JSON.parse("[]"),
          };
        }
        return prev;
      })
    );

    const inputJSON: string = JSON.stringify([{ ItemNo: itemno }]);

    const res = await runFetch(
      spURL_POST + "Portal_PriceChange_Comment_Remove",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    }
  };

  const ApproveItems = async () => {
    const inputJSON: string = JSON.stringify([{ ItemNo: SelectedItemList }]);

    setVerifiedUser(true);

    const res = await runFetch(
      spURL_POST + "Portal_PriceChange_Approve",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMessage(resObj.exception);
    } else {
      setPriceChangeItemList(
        PriceChangeItemList.map((item, index) => {
          if (SelectedItemList.includes(item.ItemNo)) {
            return {
              ...item,
              Approved: 1,
            };
          }
          return item;
        })
      );
      setSelectedItemList([]);
    }
  };

  useEffect(() => {
    getFilterList();
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  useEffect(() => {
    setSelectedItemList([]);
  }, [ProductView]);

  return (
    <div>
      <Header
        showSearch={showSearch}
        fromPLP={false}
        ProductView={ProductView}
        setProductView={setProductView}
        setSearchDescription={setSearchDescription}
        handleClick_Search={handleClick_Search}
        handleClick_Filter={handleClick_Filter}
        handleClick_Sort={handleClick_Sort}
        handleClick_AddItem={handleClick_AddItem}
        handleClick_Process={handleClick_Process}
        handleClick_BuyerSummary={handleClick_BuyerSummary}
        handleClick_Approve={handleClick_Approve}
        handleClick_Summary={handleClick_Summary}
        handleClick_SelectAll={handleClick_SelectAll}
        handleClick_SelectNone={handleClick_SelectNone}
      />
      <div>
        {/* {IsGridLoading ? <Spinner className="spinner" animation="border" /> : <>{renderItems()}</>} */}
        {IsGridLoading ? (
          <Spinner
            className="spinner"
            animation="border"
          />
        ) : (
          <>
            <p className="error-message">{Message}</p>

            <div className="card-flexbox">
              {PriceChangeItemList.map((item, index) => (
                // <div
                //   className={`card-large-wrapper ${
                //     SelectedItemList.includes(item.ItemNo)
                //       ? "selected"
                //       : item.Approved === 1
                //       ? "approved"
                //       : ""
                //   }`
                // }
                //   // onClick={() => {
                //   //   toggleCardSelect(item.ItemNo);
                //   // }}
                // >
                <div
                  className={`card-large-wrapper             
                ${item.Approved === 1 ? "approved" : ""}`}
                >
                  <ItemCardPriceChange
                    key={index}
                    {...item}
                    IsSelected={SelectedItemList.includes(item.ItemNo)}
                    ToggleCardSelect={toggleCardSelect}
                    ChangeNewSellPrice={changeNewSellPrice}
                    ChangeDate={changeDate}
                    UpdatePriceChangeItemList={updatePriceChangeItemList}
                    RemoveComment={removeComment}
                  />
                </div>
              ))}
            </div>
          </>
        )}

        <MyOffcanvas
          show={showFilter}
          setShow={setShowFilter}
          OffcanvasTitle="All filters"
          OffcanvasComponent={
            <>
              <div className="offcanvas-button-container">
                <div
                  className="offcanvas-filter-clearall btnHover"
                  onClick={handleClick_Clear}
                >
                  Clear All
                </div>
                {enableCustomFilter === false && (
                  <>
                    <div
                      className="offcanvas-filter-clearall btnHover"
                      onClick={handleClick_CustomFilter}
                    >
                      Custom Filter
                    </div>
                  </>
                )}
              </div>
              {enableCustomFilter === false && (
                <>
                  {renderPriceChangeFilters()}
                  {renderItemFilters()}
                  {renderBuyerFilters()}
                  {renderSupplierFilters()}
                  {renderMiscFilters()}
                </>
              )}
            </>
          }
        />

        <MyOffcanvas
          show={showSort}
          setShow={setShowSort}
          OffcanvasTitle="Sort"
          OffcanvasComponent={<ul className="sort-list-container">{renderSort()}</ul>}
        />
      </div>

      <PopupSummary
        inputJSON={JSON.stringify([{ ItemNo: SelectedItemList }])}
        show={showSummary}
        setShow={setShowSummary}
      />
      <PopupCustomFilter
        show={showCustomFilter}
        action="Apply"
        setShow={setShowCustomFilter}
        doLookup={(batchNo) => {
          setEnableCustomFilter(true);
          setBatchID(batchNo);
          getItems(batchNo);
        }}
      />

      <PopupCustomFilter
        show={showAddItems}
        action="Add"
        setShow={setShowAddItems}
        doLookup={(batchNo) => {
          addNewItems(batchNo);
          getItems(batchNo);
        }}
      />

      <PopupUserVerify
        show={showUserVerify}
        setShow={setShowUserVerify}
        handleClick_Verified_Approve={ApproveItems}
      />

      <PopupProcess
        show={showProcess}
        setShow={setShowProcess}
      />

      <PopupBuyerSummary
        show={showBuyerSummary}
        setShow={setShowBuyerSummary}
      />
    </div>
  );
}

export default PriceChange;
