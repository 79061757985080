import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { ReactComponent as SVGImport } from "./../assets/img/Import.svg";
import { ReactComponent as SVGPriceChange } from "./../assets/img/PriceChange.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

function HeaderHome() {
  const handleClick_BuyingPortal = () => {
    window.open("/BuyingPortal", "_blank")?.focus();
  };

  const handleClick_Import = () => {
    window.open("/ImportImages", "_blank")?.focus();
  };

  const handleClick_PriceChange = () => {
    window.open("/PriceChange", "_blank")?.focus();
  };

  const renderFunctionListLeft = () => {
    const arrFunctionList = [
      {
        Content: (
          <li
            key="import"
            className="header-function-list btnHover"
            onClick={handleClick_BuyingPortal}
          >
            <FontAwesomeIcon icon={solid("hand-holding-dollar")} />
            <span className="mobile-only-hidden">Buying Portal</span>
          </li>
        ),
      },
      {
        Content: (
          <li
            key="priceChange"
            className="header-function-list btnHover"
            onClick={handleClick_PriceChange}
          >
            <SVGPriceChange />
            <span className="mobile-only-hidden">Price Change</span>
          </li>
        ),
      },
    ];
    return arrFunctionList.map((func, index) => func.Content);
  };

  return (
    <>
      <header className="header-container sticky noselect">
        <div className="header-function-container">
          <ul className="header-function-list-container">{renderFunctionListLeft()}</ul>
        </div>
      </header>
    </>
  );
}

export default HeaderHome;
