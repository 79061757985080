export const ConvertToCurrency = (amt: number, round: number = 2, currency: string = "$") => {
  // return currency + amt.toFixed(round);
  return (
    currency +
    amt.toLocaleString(undefined, { minimumFractionDigits: round, maximumFractionDigits: round })
  );
};

export const ConvertToDollars = (_amount: number) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(_amount);
};
