import React, { useEffect, SetStateAction, useState, useRef } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../functions/RunFetch";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { PriceChangeItemObj } from "../pages/PriceChange/PriceChange";

type PopupProps = {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  itemNo: string;
  UpdatePriceChangeItemList?: (arg0: string, arg1: string) => void;
};

function CommentPopup({ show, setShow, itemNo, UpdatePriceChangeItemList }: PopupProps) {
  const refComment = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setShow(false);
  };

  const handleClick_AddComment = async () => {
    if (refComment.current?.value !== "") {
      const inputJSON: string = JSON.stringify([
        {
          ItemNo: itemNo,
          Comment: refComment.current?.value,
          User: sessionStorage.getItem("userid"),
        },
      ]);

      const res = await runFetch(
        spURL_POST + "Portal_PriceChange_Comment_Add",
        getReqOpt_POST(inputJSON)
      );

      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        console.log(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          // update item list state here with new "comment" value
          if (UpdatePriceChangeItemList !== undefined) {
            UpdatePriceChangeItemList(itemNo, spResponse.Table[0].BuyerComment);
          }
        }
      }
    }
    setShow(false);
  };

  return (
    <div>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="default-alignment">
            <input
              className="pricechange-comment-input"
              ref={refComment}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="popup-button-container">
            <div>
              <Button onClick={handleClose}>Close</Button>
            </div>
            <div>
              <Button onClick={handleClick_AddComment}>Add</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CommentPopup;
