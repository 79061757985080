import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Col, FormCheck } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

type FilterAccordionProps = {
  FilterName: string;
  Items: string[];
  FilterItems: string[];
  SelectedItems: string[];
  OnFilterChange: (list: string[]) => void;
  clearTriggered: boolean;
};

function SmartFilterAccordion({
  FilterName,
  Items,
  FilterItems,
  SelectedItems,
  OnFilterChange,
  clearTriggered,
}: FilterAccordionProps) {
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const handleChange_FilterCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked: boolean = event.currentTarget.checked;
    const checkedValue: string = event.currentTarget.value;
    let tmpSelectedFilterItems: string[] = SelectedItems;
    if (isChecked) {
      tmpSelectedFilterItems = tmpSelectedFilterItems.concat([checkedValue]);
    } else {
      tmpSelectedFilterItems = tmpSelectedFilterItems.filter((item) => item !== checkedValue);
    }
    OnFilterChange(tmpSelectedFilterItems);
    // setSelectedFilterItems(tmpSelectedFilterItems);
  };

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="text-align-center">
          <b>{FilterName}</b>
        </Accordion.Header>
        <Accordion.Body className="accordion-scroll">
          {/* {FilterItems.map((item, index) => (
            <Col
              key={index}
              data-item={item}
              value={item}
            >
              <FormCheck
                type="checkbox"
                // id={`default-checkbox-${FilterName + item.replace(" ", "-")}`}
                id={`default-checkbox-${FilterName + index}`}
                label={item}
                value={item}
                data-item={item}
                checked={selectedFilterItems.includes(item)}
                onChange={handleChange_FilterCheckbox}
              />
            </Col>
          ))} */}
          {Items.map((item, index) => (
            <Col
              key={index}
              data-item={item}
              value={item}
            >
              <FormCheck
                type="checkbox"
                // id={`default-checkbox-${FilterName + item.replace(" ", "-")}`}
                id={`default-checkbox-${FilterName + index}`}
                label={item}
                value={item}
                data-item={item}
                checked={SelectedItems.includes(item)}
                onChange={handleChange_FilterCheckbox}
                className={FilterItems.includes(item) ? "" : "hidden"}
              />
            </Col>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default SmartFilterAccordion;
