import React, { useEffect } from "react";
import Header from "../../components/Header";
import HomeButton from "../../components/HomeButton";
import ItemPLP from "../ItemPLP/ItemPLP";
import SignIn from "./SignIn";
import TodaysSales from "../TodaysSales/TodaysSales";
import HeaderHome from "../../components/HeaderHome";

function Home() {
  const renderHomeButtons = () => {
    const menuList = [
      { Title: "Buying Portal", Link: "BuyingPortal" },
      { Title: "Today's Sales", Link: "TodaysSales" },
      { Title: "Import Images", Link: "ImportImages" },
    ];
    return menuList.map((item) => (
      <HomeButton
        ButtonText={item.Title}
        LinkTo={item.Link}
      />
    ));
  };

  const renderHomePage = () => {
    switch (sessionStorage.getItem("issignedin") || "0") {
      case "1":
        // return <ItemPLP />;
        return <div className="handheld-body-container">{renderHomeButtons()}</div>;
      case "0":
        return <SignIn />;
    }
  };

  return (
    <>
      <HeaderHome />
      <TodaysSales />
    </>
  );
}

export default Home;
