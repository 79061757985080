import React, { useEffect, SetStateAction, useState, useRef } from "react";
import { getReqOpt_POST, runFetch, spURL_POST } from "../functions/RunFetch";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ConvertToCurrency } from "../functions/ConvertToCurrency";

type PopupProps = {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
};

interface ProcessItemObj {
  ItemNo: string;
  Description: string;
  PriceChangeDate: string;
  CurrentSellPrice: number;
  NewSellPrice: number;
  Reason: string;
}

interface ProcessItemErrorObj {
  ItemNo: string;
  Description: string;
  CurrentSellPrice: number;
  NewSellPrice: number;
  Reason: string;
  ErrorMsg: string;
}

function ProcessPopup({ show, setShow }: PopupProps) {
  const [ProcessItemList, setProcessItemList] = useState<ProcessItemObj[]>([]);
  const [ProcessItemFutureList, setProcessItemFutureList] = useState<ProcessItemObj[]>([]);
  const [ProcessItemErrorList, setProcessItemErrorList] = useState<ProcessItemErrorObj[]>([]);
  const [showProcessed, setShowProcessed] = useState<boolean>(false);
  const [weekNo, setWeekNo] = useState<number>(0);
  const [markup, setMarkup] = useState<number>(0);
  const [markdown, setMarkdown] = useState<number>(0);
  const [noStock, setNoStock] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShowProcessed(false);
    getItems();
  };

  const getItems = async () => {
    const inputJSON: string = JSON.stringify([
      {
        ActionType: "CHECK",
        User: sessionStorage.getItem("userid"),
      },
    ]);
    const res = await runFetch(
      spURL_POST + "Portal_PriceChange_Process",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      console.log(resObj.exception);
    } else if (resObj.status === "OK!") {
      if (resObj.response.length > 0) {
        const spResponse: any = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setProcessItemList(
            spResponse.Table.map((item: ProcessItemObj) => ({
              ItemNo: item.ItemNo,
              Description: item.Description,
              PriceChangeDate: item.PriceChangeDate,
              CurrentSellPrice: item.CurrentSellPrice,
              NewSellPrice: item.NewSellPrice,
              Reason: item.Reason,
            }))
          );
        }
        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          setProcessItemFutureList(
            spResponse.Table1.map((item: ProcessItemObj) => ({
              ItemNo: item.ItemNo,
              Description: item.Description,
              PriceChangeDate: item.PriceChangeDate,
              CurrentSellPrice: item.CurrentSellPrice,
              NewSellPrice: item.NewSellPrice,
              Reason: item.Reason,
            }))
          );
        }
        if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
          setWeekNo(spResponse.Table2[0].WeekNumber);
          setMarkup(spResponse.Table2[0].Markup);
          setMarkdown(spResponse.Table2[0].Markdown);
          setNoStock(spResponse.Table2[0].NoStock);
          setTotal(spResponse.Table2[0].Total);
        }
      }
    }
  };

  const processItems = async (ActionType: string) => {
    // if (showProcessed === false) {
    setShowProcessed(true);
    setProcessItemErrorList([]);

    const inputJSON: string = JSON.stringify([
      {
        ActionType: ActionType,
        User: sessionStorage.getItem("userid"),
      },
    ]);

    const res = await runFetch(
      spURL_POST + "Portal_PriceChange_Process",
      getReqOpt_POST(inputJSON)
    );

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      console.log(resObj.exception);
    } else if (resObj.status === "OK!") {
      if (resObj.response !== "") {
        const spResponse: any = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setProcessItemErrorList(
            spResponse.Table.map((item: ProcessItemErrorObj) => ({
              ItemNo: item.ItemNo,
              Description: item.Description,
              CurrentSellPrice: item.CurrentSellPrice,
              NewSellPrice: item.NewSellPrice,
              Reason: item.Reason,
              ErrorMsg: item.ErrorMsg,
            }))
          );
        }
      }
    }
    // }
  };

  const renderPopupBody = () => {
    let PopupBody;
    if (ProcessItemList.length === 0) {
      PopupBody = (
        <strong className="default-alignment">
          There are no approved items that can be processed this week
        </strong>
      );
    } else {
      PopupBody = (
        <>
          <div className="price-change-process-summary-container">
            <div className="default-alignment">
              <strong>Approved Items - Week {weekNo}</strong>
            </div>
            <div className="default-alignment">
              <table className="itemlist-table">
                <thead className="bold">
                  <tr>
                    <td>Price Change Type</td>
                    <td>No. Of Items</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="cell-pading-5">Mark Up w/ Stock</td>
                    <td className="cell-pading-5">{markup}</td>
                  </tr>
                  <tr>
                    <td className="cell-pading-5">Markdown w/ Stock</td>
                    <td className="cell-pading-5">{markdown}</td>
                  </tr>
                  <tr>
                    <td className="cell-pading-5">Price Change No Stock</td>
                    <td className="cell-pading-5">{noStock}</td>
                  </tr>
                  <tr>
                    <td className="cell-pading-5">Total</td>
                    <td className="cell-pading-5">{total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="default-alignment">
              <table className="itemlist-table">
                <thead className="bold">
                  <tr>
                    <td>ItemNo</td>
                    <td>Description</td>
                    <td>Start Date</td>
                    <td>Current Price</td>
                    <td>New Price</td>
                    <td>Reason</td>
                  </tr>
                </thead>
                <tbody>
                  {ProcessItemList.map((item, index) => (
                    <tr>
                      <td key={"PI.ItemNo" + index} className="cell-pading-5">
                        {item.ItemNo}
                      </td>
                      <td key={"PI.Description" + index} className="cell-pading-5">
                        {item.Description}
                      </td>
                      <td key={"PI.PriceChangeDate" + index} className="cell-pading-5">
                        {item.PriceChangeDate}
                      </td>
                      <td key={"PI.CurrentSellPrice" + index} className="cell-pading-5">
                        {ConvertToCurrency(item.CurrentSellPrice)}
                      </td>
                      <td key={"PI.NewSellPrice" + index} className="cell-pading-5">
                        {ConvertToCurrency(item.NewSellPrice)}
                      </td>
                      <td key={"PI.Reason" + index} className="cell-pading-5">
                        {item.Reason}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {ProcessItemFutureList.length !== 0 ? (
              <>
                <div className="default-alignment">
                  <strong>Approved Items - Future Weeks</strong>
                </div>
                <div className="default-alignment">
                  <table className="itemlist-table">
                    <thead className="sticky bold">
                      <tr>
                        <td>ItemNo</td>
                        <td>Description</td>
                        <td>Start Date</td>
                        <td>Current Price</td>
                        <td>New Price</td>
                        <td>Reason</td>
                      </tr>
                    </thead>
                    <tbody>
                      {ProcessItemFutureList.map((item, index) => (
                        <tr>
                          <td key={"PIF.ItemNo" + index} className="cell-pading-5">
                            {item.ItemNo}
                          </td>
                          <td key={"PIF.Description" + index} className="cell-pading-5">
                            {item.Description}
                          </td>
                          <td key={"PIF.PriceChangeDate" + index} className="cell-pading-5">
                            {item.PriceChangeDate}
                          </td>
                          <td key={"PIF.CurrentSellPrice" + index} className="cell-pading-5">
                            {ConvertToCurrency(item.CurrentSellPrice)}
                          </td>
                          <td key={"PIF.NewSellPrice" + index} className="cell-pading-5">
                            {ConvertToCurrency(item.NewSellPrice)}
                          </td>
                          <td key={"PIF.Reason" + index} className="cell-pading-5">
                            {item.Reason}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      );
    }
    return PopupBody;
  };

  const renderProcessed = () => {
    let PopupBody;
    if (ProcessItemErrorList.length === 0) {
      PopupBody = (
        <strong className="default-alignment">
          Price changes have been sucessfully processed!
        </strong>
      );
    } else {
      PopupBody = (
        <>
          <div className="default-alignment">
            <strong>Errors</strong>
          </div>
          <div className="default-alignment">
            <table className="itemlist-table">
              <thead className="sticky bold">
                <tr>
                  <td>ItemNo</td>
                  <td>Description</td>
                  <td>Current Price</td>
                  <td>New Price</td>
                  <td>Reason</td>
                  <td>Error</td>
                </tr>
              </thead>
              <tbody>
                {ProcessItemErrorList.map((item, index) => (
                  <tr>
                    <td key={"PIE.ItemNo" + index} className="cell-pading-5">
                      {item.ItemNo}
                    </td>
                    <td key={"PIE.Description" + index} className="cell-pading-5">
                      {item.Description}
                    </td>
                    <td key={"PIE.CurrentSellPrice" + index} className="cell-pading-5">
                      {ConvertToCurrency(item.CurrentSellPrice)}
                    </td>
                    <td key={"PIE.NewSellPrice" + index} className="cell-pading-5">
                      {ConvertToCurrency(item.NewSellPrice)}
                    </td>
                    <td key={"PIE.Reason" + index} className="cell-pading-5">
                      {item.Reason}
                    </td>
                    <td key={"PIE.ErrorMsg" + index} className="cell-pading-5">
                      {item.ErrorMsg}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="default-alignment">
            <Button onClick={() => processItems("PROCESS-REMAINING")}>
              Process remaining items
            </Button>
          </div>
        </>
      );
    }
    return PopupBody;
  };

  const CheckGet = () => {
    if (show === true) {
      getItems();
    }
  };

  useEffect(() => {
    CheckGet();
  }, []);

  return (
    <div>
      <Modal
        size={showProcessed === true ? "xl" : "lg"}
        show={show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="grid-container">
            {showProcessed === true ? renderProcessed() : renderPopupBody()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="popup-button-container">
            <div>
              <Button onClick={handleClose}>Close</Button>
            </div>
            <div>
              {showProcessed === false ? (
                <Button onClick={() => processItems("PROCESS")}>Process</Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProcessPopup;
